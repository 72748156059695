<template>
  <div class="container-fluid">
      <div class="table-wrapper">
               <div class="table-title">
                    <div class="row">
                    <div class="col-sm-12 text-center">
                        <router-link to="/products" style="float:left"  @click="$router.go(-1)" class="btn btn-success">Back</router-link>
                        <h2>Product <b>Details</b></h2>
                    </div>
                    
                </div>
               </div>
               <table class="table table-borderless">
              <thead>
                <tr>
                  <td  scope="col"><b>Brand Name</b></td>
                  <td scope="col">{{product.brand_name}}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td ><b>Generic Name</b></td>
                  <td>{{product.generic_name}}</td>
                </tr>
                 <tr>
                  <td ><b>Other Names</b></td>
                  <td>{{product.other_names}}</td>
                </tr>
                <tr>
                  <td ><b>Global Trade Item Number</b></td>
                  <td>{{product.gtin}}</td>
                </tr>
                <tr>
                  <td ><b>Universal Prodct Code</b></td>
                  <td>{{product.upc}}</td>
                </tr>
                <tr>
                  <td ><b>Batch Number</b></td>
                  <td>{{product.batch_number ? product.batch_number : '_'}} </td>
                </tr>
                <tr>
                  <td ><b>Manufacturing Countries</b></td>
                  <td>
                        <p v-for="(country, key) in product.manufacturing_countries" :key="key">
                                {{country}}
                      </p>
                  </td>
                </tr>
                <tr>
                  <td><b>Manufacturing Locations</b></td>
                  <td>
                            <p v-for="(location, key) in product.manufacturing_locations" :key="key">
                                {{location}}
                            </p>
                        </td>
                </tr>
                <tr>
                  <td ><b>Health and Safety Registration Number</b></td>
                  <td>
                     <p v-for="(data, key) in product.health_and_safety_registeration_number" :key="key">
                          {{data}}
                      </p>
                  </td>
                </tr>
                <tr>
                  <td ><b>Certification Number</b></td>
                  <td>
                    <p v-for="(data, key) in product.certification_number" :key="key">
                          {{data}}
                      </p>
                  </td>
                </tr>
                <tr>
                  <td ><b>Standard Registration Number</b></td>
                  <td>
                     <p v-for="(data, key) in product.standards_registeration_number" :key="key">
                          {{data}}
                      </p>
                  </td>
                </tr>
                <tr>
                  <td ><b>Raw Materials List</b></td>
                  <td>{{product.raw_materials_list}}</td>
                </tr>
                <tr>
                  <td ><b>Parking Size</b></td>
                  <td>{{product.parking_size}}</td>
                </tr>
                <tr>
                  <td ><b>HS Code</b></td>
                  <td>{{product.hscode ? product.hscode.hs_code : null}}</td>
                </tr>
                <tr>
                  <td ><b>Expiry date</b></td>
                  <td>{{product.expiry_date ? product.expiry_date : '_'}} &nbsp;  
                </td>
                </tr>
              </tbody>
            </table>
        </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            product: {}
        }
    },
    created() {
        this.$store.dispatch('fetchAllProducts')
            .then(response => {
                this.product = response.data.find( product => product.id == this.$route.params.id)
            })
    }
}
</script>

<style>
.table-wrapper{
    background: #fff;
    padding: 20px 25px;
    margin: 30px 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title{
    padding-bottom: 15px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    margin: 5px 0 0;
    font-size: 24px;
    display: inline;
}
</style>